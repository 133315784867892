<template>
  <v-container fluid>
    <v-row
      justify="start"
      no-gutters
      class="mt-3 pb-4 scheduleSelection"
      v-if="
        checkReadPermission($modules.facility.schedule.slug) &&
        checkReadPermission($modules.events.schedule.slug)
      "
    >
      <div class="text-lg-right pr-0" style="width: 12rem !important;">
        <v-autocomplete
            v-model="venueServiceIds"
            label="Service"
            :items="venueServices"
            item-text="name"
            @change="getCalender()"
            item-value="venue_service_id"
            multiple
            outlined
            class="no-right-border"
            dense
            flat
            background-color="#fff"
        >
          <template
              v-if="venueServiceIds.length == venueServices.length"
              v-slot:selection="{ index }"
          >
            <span v-if="index == 0">All Services</span>
          </template>
          <template v-else v-slot:selection="{ item, index }">
            <span v-if="index == 0">{{ item.name }}</span>
            <span v-if="index == 1">, {{ item.name }}</span>
            <span v-if="index == 2">, ...</span>
          </template>

          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle">
              <v-list-item-action>
                <v-icon
                    :color="venueServiceIds.length > 0 ? 'indigo darken-4' : ''"
                >{{ icon() }}</v-icon
                >
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-autocomplete>
      </div>
<!--      <v-spacer></v-spacer>-->
<!--      <v-col md="2" justify="center">-->
<!--        <v-btn block color="#062b48" dark tile>Facility</v-btn>-->
<!--      </v-col>-->
<!--      <v-col md="2" justify="center">-->
<!--        <router-link :to="`/calendar/events`">-->
<!--          <v-btn block light tile>Events</v-btn>-->
<!--        </router-link>-->
<!--      </v-col>-->
<!--      &lt;!&ndash; <v-col-->
<!--        md="2"-->
<!--        justify="center"-->
<!--        v-if="checkReadPermission($modules.workshops.schedule.slug)"-->
<!--      >-->
<!--        <router-link :to="`/workshop-schedule`">-->
<!--          <v-btn block light tile>Workshop</v-btn>-->
<!--        </router-link>-->
<!--      </v-col> &ndash;&gt;-->
<!--      <v-spacer></v-spacer>-->
<!--      <v-col md="2" class="text-lg-right mr-2">-->
<!--        <v-autocomplete-->
<!--          v-model="venueServiceIds"-->
<!--          label="Service"-->
<!--          :items="venueServices"-->
<!--          item-text="name"-->
<!--          @change="getCalender()"-->
<!--          item-value="venue_service_id"-->
<!--          multiple-->
<!--          outlined-->
<!--          background-color="#fff"-->
<!--        >-->
<!--          <template-->
<!--            v-if="venueServiceIds.length == venueServices.length"-->
<!--            v-slot:selection="{ index }"-->
<!--          >-->
<!--            <span v-if="index == 0">All Services</span>-->
<!--          </template>-->
<!--          <template v-else v-slot:selection="{ item, index }">-->
<!--            <span v-if="index == 0">{{ item.name }}</span>-->
<!--            <span v-if="index == 1">, {{ item.name }}</span>-->
<!--            <span v-if="index == 2">, ...</span>-->
<!--          </template>-->

<!--          <template v-slot:prepend-item>-->
<!--            <v-list-item ripple @click="toggle">-->
<!--              <v-list-item-action>-->
<!--                <v-icon-->
<!--                  :color="venueServiceIds.length > 0 ? 'indigo darken-4' : ''"-->
<!--                  >{{ icon() }}</v-icon-->
<!--                >-->
<!--              </v-list-item-action>-->
<!--              <v-list-item-content>-->
<!--                <v-list-item-title>Select All</v-list-item-title>-->
<!--              </v-list-item-content>-->
<!--            </v-list-item>-->
<!--            <v-divider class="mt-2"></v-divider>-->
<!--          </template>-->
<!--        </v-autocomplete>-->
<!--      </v-col>-->
    </v-row>
    <v-card class="mt-2" style="border-radius: 12px">
    <v-row >
<!--      <v-col sm="4">-->
<!--        <v-btn @click="gotoSchedule(date)" light tile>Day</v-btn>-->
<!--        <router-link :to="`/calender`">-->
<!--          <v-btn color="#062b48" dark tile>Month</v-btn>-->
<!--        </router-link>-->
<!--      </v-col>-->
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-col sm="4">
        <v-row no-gutters>
          <v-col sm="1" class="text-lg-center mr-1">
            <v-btn fab x-small color="white" @click="prevDate">
              <v-icon dark>mdi-menu-left</v-icon>
            </v-btn>
          </v-col>
          <v-col sm="8" class="text-lg-center">
            <date-field v-model="date" :buttonAndText="true" @change="getCalender">
            </date-field>
          </v-col>
          <v-col sm="1" class="text-lg-center ml-1">
            <v-btn fab  x-small color="white" @click="nextDate">
              <v-icon dark>mdi-menu-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col sm="4" style="text-align:right">
        <v-btn @click="gotoSchedule(date)"  tile text style="font-weight: 400; font-size:16px; line-height: 20px" >Day</v-btn>
        |
        <router-link :to="`/schedule`">
          <v-btn color="#062b48" light tile text style="font-weight: 600; font-size:16px; line-height: 20px" >Month</v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row class="pa-4"> </v-row>
    <div class="month_header">{{ this.currentMonth }}</div>
    <div
      style="
        width: 14.28%;
        background: #ffffff;
        padding: 0px;
        color: black;
        border-radius: 3px;
        border-bottom: 1px solid #ededed;
        border-right: 1px solid #ededed;
      "
      class="month_header d-sm-inline-flex"
      v-for="day in weekdays"
      :key="day"
    >
      <v-col style="text-align: center"> {{ day }} </v-col>
    </div>

    <div v-for="(cData, index) in data" :key="index">
      <div class="calander_col">
        <div style="cursor: pointer" @click="viewDay(cData.day_index)">
          <div class="pa-2 m_booking date">{{ cData.day_index }}</div>

          <table
            class="booking_column calendarBox"
            v-if="
              cData.bookings &&
              (cData.bookings.sales > 0 || cData.bookings.booking > 0)
            "
          >
            <tr>
              <th class="pl-1">Bookings</th>
              <th>
                {{ Number(cData.bookings.booking || 0) | numberFormatter }}
              </th>
            </tr>
            <tr>
              <th class="pl-1">Sales</th>
              <th>{{ Number(cData.bookings.sales || 0) | toCurrency }}</th>
            </tr>
          </table>
          <table
            class="reservation_column calendarBox"
            v-if="
              cData.reservations &&
              (cData.reservations.sales > 0 || cData.reservations.booking > 0)
            "
          >
            <tr>
              <th class="pl-1">Bookings</th>
              <th>
                {{ Number(cData.reservations.booking || 0) | numberFormatter }}
              </th>
            </tr>
            <tr>
              <th class="pl-1">Sales</th>
              <th>{{ Number(cData.reservations.sales || 0) | toCurrency }}</th>
            </tr>
          </table>
        </div>
      </div>
    </div>
    </v-card>
  </v-container>
</template>
<script>
import moment from "moment";
export default {
  data: () => ({
    date: moment().format("YYYY-MM-DD"),
    data: [],
    venueServiceIds: [],
    weekdays: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
    currentMonth: moment().format("MMMM"),
  }),
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.showLoader("Loading");
      this.$store.dispatch("loadVenueServices").then(() => {
        this.$nextTick(() => {
          if (this.$store.getters.getSportsService.length) {
            this.getRouteParams();
            this.hideLoader();
            this.getCalender();
          }
        });
      });
    } else {
      if (this.$store.getters.getSportsService.length) {
        this.getRouteParams();
        this.getCalender();
      }
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getSportsService;
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.venueServiceIds.length == this.venueServices.length) {
          this.venueServiceIds = [];
        } else {
          this.venueServiceIds = this.venueServices.map(
            (item) => item.venue_service_id
          );
        }
      });
      setTimeout(() => {
        this.getCalender();
      });
    },
    icon() {
      if (this.venueServiceIds.length == this.venueServiceIds)
        return "mdi-close-box";
      if (this.venueServiceIds.length == 0) return "mdi-checkbox-blank-outline";
      return "mdi-minus-box";
    },
    getDaysArray(date) {
      var firstDayOfMonth = moment(date).startOf("month");
      var firstDayOfCal = firstDayOfMonth.clone().startOf("week");
      var lastDayOfMonth = firstDayOfMonth.clone().endOf("month");
      var lastDayOfCal = lastDayOfMonth.clone().endOf("week");
      var temp = firstDayOfCal.clone();
      var days = [temp.toDate()];
      while (temp.isBefore(lastDayOfCal) && days.length < 14) {
        temp.add(1, "day");
        days.push(temp.toDate());
      }
      while (days.length < 14) {
        temp.add(1, "day");
        days.push(temp.toDate());
      }
      return days;
    },
    getRouteParams() {
      if (this.$route.params.data) {
        let data = JSON.parse(atob(this.$route.params.data));
        this.venueServiceIds.push(data.venue_service.venue_service_id);
        this.date = data.date;
      } else {
        this.venueServiceIds.push(this.$store.getters.getSportsService[0]);
      }
      this.getCalender();
    },
    getEventColor(event) {
      return event.color ? event.color : "teal";
    },
    nextDate() {
      this.date = moment(this.date).add(1, "month").format("YYYY-MM-DD");
      this.getCalender();
    },
    prevDate() {
      this.date = moment(this.date).subtract(1, "month").format("YYYY-MM-DD");
      this.getCalender();
    },
    getCalender() {
      this.data = [];

      this.showLoader("Loading Calender");

      let url = `venues/facilities/bookings/calender?date=${this.date}`;
      url += this.venueServiceIds
        .map((item, index) => `&venue_service_ids[${index}]=${item}`)
        .join(",");

      this.$http
        .get(url)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.data = response.data.data;
            console.log(this.data);
            var dateArray = this.getDaysArray(this.date);
            this.currentMonth = moment(this.date).format("MMMM");
            for (let i = 0; i < dateArray.length; i++) {
              if (moment(dateArray[i]).format("DD") == "01") {
                break;
              }
              this.data.unshift({
                prev_month_day: dateArray[i],
              });
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    viewDay(data) {
      var joinDate = this.date.substring(0, 8) + data;
      const date = new Date(joinDate);
      this.gotoSchedule(moment(date).format("YYYY-MM-DD"));
    },
    gotoSchedule(date) {
      let venueService = this.$store.getters.getSportsService.find(
        (x) => x.id == this.venueServiceIds[0]
      );
      this.$router.push({
        name: "ScheduleWithParams",
        params: {
          data: btoa(
            JSON.stringify({
              venue_service: venueService,
              date: date,
            })
          ),
        },
      });
    },
  },
};
</script>
<style scoped>
.month_calander {
  margin: 30px 30px 30px;
}
.calander_col {
  width: 14.28%;
  float: left;
  text-align: left;
  outline: #f3f2f2 0;
  background: #fff;
  min-height: 162px;
  border-radius: 3px;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
}
.booking_column {
  background: rgba(0, 89, 118, 0.767) !important;
}
.reservation_column {
  background: rgb(206, 168, 0) !important;
}
.m_booking a {
  color: #fff;
  font-size: 100%;
  text-decoration: none;
}

.booking {
  text-align: center;
  font-size: 14px;
}
.date {
  color: #0794c0;
  font-weight: bold;
  font-size: 20px;
}

.month_header {
  background: rgb(233, 241, 246);
  color: black;
  padding: 15px;
  font-weight: bold;
  border-radius: 0px;
}
.search {
  padding: 2px;
}
.booking_menu a {
  text-decoration: none;
  font-size: 12px;
  color: aliceblue;
  font-weight: bold;
  padding: 0px 15px;
}
.date a {
  text-decoration: none;
}
.day {
  background: #2c3b46;
  border-radius: 8px 0 0 8px;
}
.month {
  background: #66c8c8;
  border-radius: 0px 8px 8px 0px;
}
.btns {
  width: 100px;
  text-align: center;
}
.calendarBox {
  color: #fff;
  text-align: left;
  width: 100%;
}
.calendarBox tr {
  line-height: 1.6em;
}
.butn {
  width: 90px;
}
.no-right-border{
  .v-input__control{
    border-radius: 5px 0 0 5px; /* Adjust border radius as needed */
  }
  color: #4FAEAF !important;
  fieldset{
    border:1px solid rgba(17, 42, 70, 0.1);
  }
}

</style>
